<template>
    <LiefengContent>
        <template v-slot:title>查看所有组</template>
        <template v-slot:toolsbarRight>
            <Button style="margin-right: 10px" @click="addCustomBtn" type="primary">取消所有</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                height="200"
                :loading="loading"
                :fixTable="true"
                :hidePage="true"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/groupindex")
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            loading: false,
            total: 0,
            tableData: [],
            talbeColumns: [
                {
                    title: "群名称",
                    minWidth: 130,
                    key: "groupName",
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 300,
                    align: "center",

                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    display: "flex",
                                    justifyContent: "center",
                                },
                            },
                            [
                                h(
                                    "Dropdown",
                                    {
                                        props: {
                                            transfer: true,
                                        },
                                    },
                                    [
                                        h(
                                            "Button",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.cancelAll([params.row.groupId])
                                                    },
                                                },
                                                style: {
                                                    textAlign: "center",
                                                    marginLeft: "20px",
                                                },
                                                attrs: {
                                                    type: "info",
                                                    size: "small",
                                                },
                                            },
                                            "取消管理员"
                                        ),
                                    ]
                                ),
                            ]
                        )
                    },
                },
            ],
            page: 1,
            pageSize: 20,
        }
    },
    methods: {
        addCustomBtn(){
            if(this.tableData && this.tableData.length){
                let list = []
                this.tableData.map(item =>{
                    list.push(item.groupId)
                })
                this.cancelAll(list)
            }else{
                this.$Message.warning({
                    content:'暂没有可取消的群',
                    background:true
                })
            }
        },
        cancelAll(groupIdList) {
            this.$Message.loading({
                content: "正在添加",
            })
            this.$post(
                "/sendmsg/pc/chat/groupmanager/removeManager",
                {
                    custGlobalId: this.$route.query.custGlobalId,
                    groupIdList,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.$Message.destroy()
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    this.getList()
                    return
                } else {
                    this.$Message.error({
                        content: "操作失败",
                        background: true,
                    })
                }
            })
        },
        getList() {
            this.loading = true
            this.$get("/sendmsg/pc/chat/groupmanager/selectManagerHasGroup", {
                custGlobalId: this.$route.query.custGlobalId,
            }).then(res => {
                if (res && res.code == 200) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.pageSize = res.pageSize
                    this.loading = false
                }
            })
        },
    },
    async created() {
        this.getList()
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
    },
}
</script>

<style scoped lang="less">
/deep/.ivu-input-wrapper {
    width: 200px;
}
/deep/.ivu-checkbox-group-item {
    margin: 5px;
}
</style>
